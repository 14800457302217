import { render, staticRenderFns } from "./parser.vue?vue&type=template&id=b380e8a2&scoped=true"
import script from "./parser.vue?vue&type=script&lang=js"
export * from "./parser.vue?vue&type=script&lang=js"
import style0 from "./parser.vue?vue&type=style&index=0&id=b380e8a2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b380e8a2",
  null
  
)

export default component.exports
<template>
  <Layout>
    <PageHeader :items="getItems()" :title="`${$t('parsers.create-or-edit.title')} - &quot;${warehouseName}&quot;`"/>
    <delete-parser-modal ref="deleteParserModal"/>
    <parser-tag-preview-modal ref="parserTagPreviewModal"/>
    <parser-access-data-modal ref="parserAccessDataModal"/>
    <parser-change-price-type-modal ref="parserChangePriceTypeModal"/>
    <parser-quantity-mappings-modal ref="parserQuantityMappingsModal"/>
    <parser-images-text-separator-modal ref="parserImagesTextSeparatorModal"/>
    <parser-image-url-prefix-modal ref="parserImageUrlPrefixModal"/>
    <parser-reject-match-modal ref="parserRejectMatchModal"/>
    <parser-relation-ships-modal ref="parserRelationShipsModal"/>
    <parser-add-attribute-modal ref="parserAddAttributeModal"/>
    <parser-add-xml-url-modal
        :warehouse-id="warehouseId"
        @add="addNewParser"
        ref="parserAddXmlUrlModal"
    />

    <card-base>
      <div class="text-center">
        <label>Link do XML:</label>
        <p class="text-center text-warning">Jeśli chcesz zmienić kolejność parserów, przytrzymaj link i przesuń w górę
          lub w dół.</p>
      </div>

      <draggable v-model="parsers" @start="isDragging = true" @end="isDragging = false">
        <div class="row mb-2 d-flex justify-content-center pe-2" v-for="(parserObject, index) in parsers" :key="index"
             :class="{ 'dragging-parser-animation': isDragging }">
          <div class="input-wrapper w-25 mr-2">
            <input v-model="parserObject.parser.xmlUrl" type="text" class="form-control w-100" :disabled="true"/>
            <div class="error-code" v-if="parserObject.parser.errorMessage">
              <span class="mdi mdi-close"></span> {{ parserObject.parser.errorMessage }}
            </div>
          </div>

          <div class="button-items">
            <b-button @click="deleteParserAsUser(parserObject)" variant="danger"><i class="fa fa-times"/></b-button>
            <b-button @click="$refs.parserAccessDataModal.openModal(parserObject)" class="px-4" variant="primary">Dane
              dostępowe
            </b-button>
            <b-button @click="$refs.parserRelationShipsModal.openModal(parserObject)" class="px-4" variant="success">
              Relacje
            </b-button>
          </div>
        </div>
      </draggable>

      <div class="text-center py-3">
        <div class="button-items">
          <b-button variant="success" @click="$refs.parserAddXmlUrlModal.openModal()"><i class="fa fa-plus"/></b-button>
          <b-button variant="primary" class="px-4" @click="processData">Przetwórz dane</b-button>
          <b-button variant="danger" class="px-4" @click="$refs.deleteParserModal.openModal(warehouseId)"
                    :disabled="parsers.length <= 0">Usuń parser
          </b-button>
        </div>
      </div>

      <div class="col-12 mb-2 p-0 m-0">
        <h5 class="text-start px-2 py-3 card-subtitle">
          Globalne ustawienia parserów hurtowni "{{ warehouseName }}"
        </h5>
      </div>

      <div class="button-items" v-if="loadedPage">
        <div class="row px-2 mb-3">
          <div class="col-12 col-lg-3" v-if="!parserAlreadyExists">
            <ecat-multiselect
                :can-unselect="true"
                :custom-params="parserAlreadyExists ? {} : {'withoutParser': true}"
                :disabled="parserAlreadyExists"
                :set-value="warehouseId"
                fetch-one-url="/warehouse"
                label="Hurtownia"
                load-url="/warehouse/list/pagination"
                param="name"
                placeholder="Wybierz hurtownie bez parsera"
                query-url="/warehouse/by-name"
                save-id="id"
                view-id="name"
                @change="value => updateWarehouseId(value)"
            />
          </div>

          <div class="col-12 col-lg-3">
            <div class="form-group">
              <label>Narzut</label>
              <input
                  v-model.number="profitMargin"
                  class="form-control custom-number-with-gap"
                  min="5"
                  step="0.1"
                  type="number"
              />
            </div>
          </div>

          <div class="col-12 col-lg-3">
            <div class="form-group d-flex flex-column">
              <label>Automatyczne aktualizowanie tabelki z produktami</label>
              <switches
                  :emit-on-mount="false"
                  v-model="filtering.automaticallyUpdateProductTable"
                  class="my-0"
                  color="primary"
              />
            </div>
          </div>

          <div class="col-12 col-lg-3">
            <div class="form-group d-flex flex-column">
              <label>Scal wszystkie produkty w jedną tabelkę</label>
              <switches
                  :emit-on-mount="false"
                  v-model="filtering.mergeProductsTable"
                  class="my-0"
                  color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </card-base>

    <template v-if="loadedPage">

      <div v-for="(parserObject, index) in parsers" :key="`parser-${index}`">
        <parser
            :parser-index="index"
            :first-parser-object="parsers.length > 0 ? parsers[0] : null"
            :warehouse-id="warehouseId"
            :profit-margin="profitMargin"
            :parser-object="parserObject"
            :tag-setup="parserObject.tagSetup"
            :required-tags="requiredTags"
            :table-filtering="filtering"
            @open-parser-quantity-mappings-modal="$refs.parserQuantityMappingsModal.openModal"
            @open-parser-change-price-type-modal="$refs.parserChangePriceTypeModal.openModal"
            @open-parser-images-text-separator-modal="$refs.parserImagesTextSeparatorModal.openModal"
            @open-parser-image-url-prefix-modal="$refs.parserImageUrlPrefixModal.openModal"
        />
      </div>

      <hr/>
    </template>

    <parser-products-table v-if="filtering.mergeProductsTable" :filtering="filtering" :warehouse-id="warehouseId"
                           :profit-margin="profitMargin" :parsers="parsers"/>

    <div class="button-items py-3">
      <b-button @click="checkMatchesAndPublish" :disabled="!isLoadedParsers()" variant="success" class="px-5">Sprawdź
        dopasowanie i opublikuj
      </b-button>
      <b-button @click="saveParsersDraft(true)" :disabled="!isLoadedParsers()" variant="secondary" class="px-5">Zapisz
        wersję roboczą parsera
      </b-button>
    </div>

    <div class="row d-flex justify-content-center py-4">
      <div class="col-12 col-lg-6">
        <card>
          <h5 class="text-center mb-4">Informacje o parserach</h5>

          <div class="table-responsive">
            <table class="table table-hover table-bordered text-center">
              <tbody>
              <tr>
                <td>Ilość ogólnie: <b>{{ parsers.length }}</b></td>
              </tr>
              <tr>
                <td>Załadowanych parserów:
                  <b>{{ processXmlTasks.filter(processXmlTask => processXmlTask.completed).length }}</b></td>
              </tr>
              <tr>
                <td>Ilość parserów w trakcie ładowania: <b>{{
                    processXmlTasks.filter(processXmlTask => !processXmlTask.completed && !processXmlTask.errorMessage).length
                  }}</b></td>
              </tr>
              <tr>
                <td>Ilość z błędem: <b>{{
                    processXmlTasks.filter(processXmlTask => processXmlTask.errorMessage).length
                  }}</b></td>
              </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>

  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import draggable from 'vuedraggable'
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import DeleteParserModal from "@/components/parser/delete-parser-modal.vue";
import Parser from "@/components/parser/parser.vue";
import ParserProductsTable from "@/components/parser/parser-products-table.vue";
import {v4 as uuidv4} from 'uuid';
import parserTagPreviewModal from "@/components/parser/parser-tag-preview-modal.vue";
import parserAccessDataModal from "@/components/parser/parser-access-data-modal.vue";
import parserChangePriceTypeModal from "@/components/parser/parser-change-price-type-modal.vue";
import parserQuantityMappingsModal from "@/components/parser/parser-quantity-mappings-modal.vue";
import parserImagesTextSeparatorModal from "@/components/parser/parser-images-text-separator-modal.vue";
import parserImageUrlPrefixModal from "@/components/parser/parser-image-url-prefix-modal.vue";
import parserRejectMatchModal from "@/components/parser/parser-reject-match-modal.vue";
import {routerHelper} from "@/helpers/router-helper";
import ParserRelationShipsModal from "@/components/parser/parser-relation-ships-modal.vue";
import parserAddAttributeModal from "@/components/parser/parser-add-attribute-modal.vue";
import parserAddXmlUrlModal from "@/components/parser/parser-add-xml-url-modal.vue";
import Switches from "vue-switches";
import {toastHelper} from "@/helpers/toast-helper";
import {queryHelper} from "@/helpers/query-helper";

export default {

  components: {
    Layout,
    PageHeader,
    draggable,
    Switches,
    Parser,
    ParserProductsTable,
    DeleteParserModal,
    parserTagPreviewModal,
    parserAccessDataModal,
    parserChangePriceTypeModal,
    parserQuantityMappingsModal,
    parserImagesTextSeparatorModal,
    parserImageUrlPrefixModal,
    parserRejectMatchModal,
    ParserRelationShipsModal,
    parserAddAttributeModal,
    parserAddXmlUrlModal
  },

  data() {
    return {
      loadedPage: false,
      isDragging: false,

      parserAlreadyExists: false,
      warehouseId: "",
      warehouseName: "",
      refreshFrequency: 60,
      profitMargin: 5,

      processXmlTasks: [],

      parsers: [],
      ecatTags: [],

      requiredTags: [
        "product",
        "warehouseProductId",
        "name",
        "description",
        "price"
      ],

      filtering: {
        automaticallyUpdateProductTable: true,
        mergeProductsTable: true
      }
    }
  },

  methods: {
    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('parsers.create-or-edit.title'),
          active: true
        }
      ]
    },

    async loadWarehouse() {
      try {
        const {data} = await axios.get(`/warehouse/${this.warehouseId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.profitMargin = data.profitMargin
        this.warehouseName = data.name;
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadParsers() {
      await this.loadWarehouse()

      this.parsers = []
      await this.$root.$emit("ecat-parser-clear-table")

      try {
        const {data} = await axios.get(`/parser/list/by/warehouse/${this.warehouseId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        data.forEach(parser => this.setParserOrUpdate(parser.xmlUrl, parser, false));

        await this.loadParserTags()
        await this.setupParserBase(true)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadParserTags() {
      try {
        const {data} = await axios.get(`/parser/tag/list/by/warehouse/${this.warehouseId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        data.forEach(parserTag => this.setParserOrUpdate(parserTag.xmlUrl, parserTag, true));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    setParserOrUpdate(xmlUrl, object, isParserTag) {
      let parserObject = this.parsers.find(element => element.parser.xmlUrl === xmlUrl)
      if (!parserObject && isParserTag) {
        this.addNewParser(xmlUrl)
      }

      parserObject = this.parsers.find(element => element.parser.xmlUrl === xmlUrl)
      if (parserObject && isParserTag) {
        parserObject.parserTag = object
        parserObject.tagSetup = JSON.parse(object.tagSetup)
      }

      if (!parserObject && !isParserTag && object) {
        this.addParser(object)
      }
    },

    addParser(parser) {
      this.parsers.push({
        loading: false,
        loaded: false,
        errorMessage: null,
        parser: parser,
        parserTag: {},
        tagSetup: {},
        leftTags: [],
        rightTags: [],
      })
    },

    addNewParser(xmlUrl) {
      if (this.parsers.some(parserObject => parserObject.parser.xmlUrl === xmlUrl)) {
        Swal.fire("Błąd!", "Taki link do pliku XML jest już dodany!", "error");
        return
      }

      this.addParser({
        frontendId: uuidv4(),
        warehouseId: this.warehouseId,
        refreshFrequency: (this.refreshFrequency * 60) * 1000,
        xmlUrl: xmlUrl
      })

      this.$refs.parserAddXmlUrlModal.hideModal()
    },

    fillTagSetup(tagSetup) {
      if (!tagSetup.rejectedTags) {
        tagSetup.rejectedTags = []
      }

      if (!tagSetup.relationships) {
        tagSetup.relationships = []
      }

      if (!tagSetup.matchedTags) {
        tagSetup.matchedTags = []
      }

      if (!tagSetup.frontendCustomTags) {
        tagSetup.frontendCustomTags = []
      }

      if (!tagSetup.frontendAttributes) {
        tagSetup.frontendAttributes = []
      }

      if (!tagSetup.accessData) {
        tagSetup.accessData = {
          username: "",
          password: ""
        }
      }

      if (!tagSetup.imagesTextSeparator) {
        tagSetup.imagesTextSeparator = ""
      }

      if (!tagSetup.imageUrlPrefix) {
        tagSetup.imageUrlPrefix = ""
      }

      if (!tagSetup.quantityMappings) {
        tagSetup.quantityMappings = {
          mappings: new Map()
        }
      }

      if (!tagSetup.priceType) {
        tagSetup.priceType = "NET"
      }

      tagSetup.quantityMappings.frontendMappings = []
      for (const key in tagSetup.quantityMappings.mappings) {
        const value = tagSetup.quantityMappings.mappings[key];
        tagSetup.quantityMappings.frontendMappings.push({
          key: key,
          value: value
        })
      }
    },

    async setupParserBase(loading = false) {
      if (this.parsers.length === 0) {
        return;
      }

      this.parsers.forEach(parserObject => {
        if (parserObject.parserTag) {
          this.fillTagSetup(parserObject.tagSetup)
        }

        if (loading) {
          parserObject.parser.refreshFrequency = (parserObject.parser.refreshFrequency / 60) / 1000
        }

        parserObject.leftTags = []
        parserObject.rightTags = []
        parserObject.rightTags.push(...this.ecatTags)
      })

      await this.processXmls()
    },

    updateWarehouseId(warehouseId) {
      this.warehouseId = warehouseId

      this.parsers.forEach(parserObject => {
        parserObject.parser.warehouseId = warehouseId

        if (parserObject.parserTag) {
          parserObject.parserTag.warehouseId = warehouseId
        }
      })
    },

    async deleteParserAsUser(parserObject) {
      const {parser, parserTag} = parserObject
      await this.deleteParser(parser)
      if (parserTag && parserTag.id) {
        await this.deleteParserTag(parser.xmlUrl, parser.warehouseId)
      }

      this.parsers = this.parsers.filter(element => {
        return parser.frontendId
            ? parser.frontendId !== element.parser.frontendId
            : element.parser.xmlUrl !== parser.xmlUrl
      })

      if (!this.parsers || this.parsers.length === 0) {
        await Swal.fire("Sukces!", "Parser został usunięty!", "success")
            .then(() => window.location = "/dashboard/admin/parser/create-or-edit");
      }
    },

    async deleteParser(parser) {
      if (!parser.id) {
        return
      }

      try {
        await axios.delete(`/parser/${parser.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async deleteParserTag(xmlUrl, warehouseId) {
      try {
        const json = {
          xmlUrl: xmlUrl,
          warehouseId: warehouseId
        }

        await axios.post(`/parser/tag/delete`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadEcatTags() {
      try {
        const {data} = await axios.get(`/parser/tag/list-ecat-tags`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.ecatTags = data.entries
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async processData() {
      if (!this.warehouseId) {
        await Swal.fire("", "Aby móc przetworzyć dane należy wybrać hurtownie.", "error");
        return
      }

      await this.setupParserBase()
    },

    async initAll() {
      await this.loadEcatTags()
    },

    async processXmls() {
      this.processXmlTasks.forEach(processXmlTask => {
        if (processXmlTask.completed || processXmlTask.error) {
          return
        }

        clearInterval(processXmlTask.taskId)
      })

      this.processXmlTasks = []
      for (const parserObject of this.parsers) {
        await this.processXml(parserObject)
      }

      const checkCompletion = async () => {
        const completedTasks = this.processXmlTasks.length > 0 && this.processXmlTasks.every(task => task.completed);

        if (completedTasks) {
          await this.$root.$emit("ecat-parser-refresh-table")
          clearInterval(interval);
        }

        if (this.processXmlTasks.some(task => task.error)) {
          clearInterval(interval);
        }
      }

      const interval = setInterval(checkCompletion, 1500);
    },

    async processXml(parserObject) {
      const {parser} = parserObject
      if (!parser.xmlUrl) {
        return
      }

      parserObject.leftTags = []
      parserObject.loading = true
      parserObject.loaded = false
      parserObject.errorMessage = ""

      try {
        const json = {
          xmlUrl: parser.xmlUrl,
          warehouseId: this.warehouseId,
          cacheTimeout: (parserObject.parser.refreshFrequency * 60) * 1000
        }

        const {data} = await axios.post(`/parser/tag/process-xml`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        const {taskId} = data
        const intervalId = setInterval(async () => {
          await this.enableProcessXmlTask(parserObject, intervalId, taskId)
        }, 3000)

        this.processXmlTasks.push({
          xmlUrl: parser.xmlUrl,
          taskId: taskId,
          intervalId: intervalId,
          completed: false,
          error: false,
        })
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async enableProcessXmlTask(parserObject, intervalId, taskId) {
      try {
        const {data} = await axios.get(`/task/${taskId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        const {status} = data

        if (status === "COMPLETED") {
          this.disableProcessXmlTask(parserObject, intervalId, taskId, true)
          parserObject.leftTags.push(...data.response.tags)
          parserObject.tagSetup.matchedTags = parserObject.tagSetup.matchedTags.filter(matchedTag => parserObject.leftTags.some(leftTag => leftTag.path === matchedTag.tagPath))
          parserObject.loaded = true
        } else if (status === "FAILED") {
          this.disableProcessXmlTask(null, intervalId, taskId)
          parserObject.errorMessage = data.response.message

          toastHelper.error(data.response.message)
        } else if (status !== "PROCESSING") {
          this.disableProcessXmlTask(parserObject, intervalId, taskId, true)
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.status === "FAILED" && error.response.data.response.message) {
          this.disableProcessXmlTask(null, intervalId, taskId)
          parserObject.errorMessage = error.response.data.response.message

          toastHelper.error(error.response.data.response.message)
        }

        errorCatcher.catchErrors(error)
      }
    },

    disableProcessXmlTask(parserObject, intervalId, taskId, setLoaded = false) {
      clearInterval(intervalId)

      if (parserObject) {
        parserObject.loading = false
      }

      const task = this.processXmlTasks.find(element => element.taskId === taskId)
      if (!task) {
        return
      }

      if (setLoaded) {
        task.completed = true
        return
      }

      task.error = true
    },

    async setProfitMargin() {
      if (this.profitMargin < 5) {
        this.profitMargin = 5
      }

      const json = {
        id: this.warehouseId,
        profitMargin: this.profitMargin
      }

      try {
        await axios.post(`/warehouse/change-profit-margin`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await Swal.fire("Sukces!", "Pomyślnie ustalono narzut", "success");
      } catch (error) {
        errorCatcher.catchErrors(error);
      }
    },

    async saveParsersDraft(draft) {
      if (!this.warehouseId) {
        await Swal.fire("", "Aby móc zapisać jako wersję roboczą należy wybrać hurtownie.", "error");
        return
      }

      queryHelper.set({
        warehouseId: this.warehouseId
      })

      await this.setProfitMargin()

      for (const parserObject of this.parsers) {
        await this.saveParserDraft(parserObject)
      }

      await Swal.fire("Sukces!", draft
              ? "Zapisano wersję roboczą parsera"
              : "Pomyślnie zaktualizowano dane we wszystkich XMLACH"
          , "success");
    },

    async saveParserDraft(parserObject) {
      const json = {
        xmlUrl: decodeURIComponent(parserObject.parser.xmlUrl),
        tagSetup: JSON.stringify(parserObject.tagSetup),
        warehouseId: this.warehouseId,
        // cacheTimeout: (parserObject.parser.refreshFrequency * 60) * 1000
      }

      try {
        await axios.post(`/parser/tag`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });
      } catch (error) {
        errorCatcher.catchErrors(error);
      }
    },

    async checkMatchesAndPublish() {
      if (!await this.checkMatches()) {
        return
      }

      await this.saveParsersDraft(false)

      let index = 0;
      let success = false;
      for (const parserObject of this.parsers) {
        parserObject.parser.index = index
        success = await this.createOrEditParser(parserObject.parser)
        ++index;
      }

      if (success) {
        await Swal.fire("Sukces!", "Pomyślnie zaktualizowano lub/i utworzono nowe parsery.", "success");
      }

      await this.loadParsers()
    },

    async checkMatches() {
      let matchedRequiredTags = 0
      let imagesOrPrimaryImageMatched = false

      let index = 0
      for (const parserObject of this.parsers) {
        if (parserObject.parser.refreshFrequency < 60) {
          toastHelper.error(this.$t('parsers.modal.refresh-frequency-min-length'))
          return false
        }

        const tagSetup = parserObject.tagSetup

        matchedRequiredTags += tagSetup.matchedTags.filter(matchedTag => this.requiredTags.includes(matchedTag.name)).length

        for (const leftTag of parserObject.leftTags) {
          if (!tagSetup.rejectedTags.includes(leftTag.path) && !tagSetup.matchedTags.find(element => element.tagPath === leftTag.path)) {
            await Swal.fire("Błąd!", `TAG ${leftTag.tagName} nie został dopasowany lub odrzucony!`, "error");
            return false
          }
        }

        if (!imagesOrPrimaryImageMatched) {
          imagesOrPrimaryImageMatched = tagSetup.matchedTags.some(element => element.name === "images")
              || tagSetup.matchedTags.some(element => element.name === "primaryImage")
        }

        if (!tagSetup.matchedTags.some(element => element.name === "product")) {
          await Swal.fire("Błąd!", "Wymagany TAG 'product' nie został dopasowany!", "error");
          return false
        } else if (!tagSetup.matchedTags.some(element => element.name === "warehouseProductId")) {
          await Swal.fire("Błąd!", "Wymagany TAG 'warehouseProductId' nie został dopasowany!", "error");
          return false
        }

        ++index
      }

      if (this.requiredTags.length >= matchedRequiredTags && index === 0) {
        await Swal.fire("Błąd!", `${this.requiredTags.length - matchedRequiredTags} wymaganych TAGÓW nie zostało dopasowanych!`, "error");
        return false
      }

      if (!imagesOrPrimaryImageMatched && index === 0) {
        await Swal.fire("Błąd!", `primaryImage lub images nie zostało dopasowane!`, "error");
        return false
      }

      return true
    },

    async createOrEditParser(parser) {
      delete parser.lastWarehouseProductIds;
      delete parser.lastWarehouseProductVariationIds;

      const json = JSON.stringify({
        ...parser,
        refreshFrequency: (parser.refreshFrequency * 60) * 1000
      });

      try {
        await axios.put(`/parser`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
        return false
      }

      return true
    },

    isLoadedParsers() {
      return this.processXmlTasks.length > 0 && this.processXmlTasks.every(processXmlTask => processXmlTask.completed)
    }

  },

  async beforeMount() {
    await this.initAll()

    const warehouseId = this.$route.params.warehouseId ? this.$route.params.warehouseId : routerHelper.params().warehouseId
    if (!warehouseId) {
      this.loadedPage = true
      return
    }

    this.warehouseId = warehouseId
    this.parserAlreadyExists = true
    this.loadedPage = true

    await this.loadParsers()
  },

  mounted() {
    this.$root.$on("open-parser-tag-preview-modal", (tag) => this.$refs.parserTagPreviewModal.openModal(tag))
    this.$root.$on("open-parser-reject-match-modal", (xmlUrl, tagSetup, tag, rightTag) => this.$refs.parserRejectMatchModal.openModal(xmlUrl, tagSetup, tag, rightTag))
    this.$root.$on("open-parser-add-attribute-modal", (parserObject) => this.$refs.parserAddAttributeModal.openModal(parserObject))
  },

  beforeDestroy() {
    this.$root.$off("open-parser-tag-preview-modal");
    this.$root.$off("open-parser-reject-match-modal")
    this.$root.$off("open-parser-add-attribute-modal")
  }


}
</script>

<style scoped lang="scss">
.input-wrapper {
  background: rgba(225, 61, 96, 0.15);

  .error-code {
    .mdi-close {
      color: #FF3D60;
    }

    font-family: Inter, sans-serif;
    font-weight: 500;

    color: #FFFFFF;

    border-radius: 0 0 5px 5px;

    padding: 10px;
  }
}
</style>
import {translationHelper} from "@/helpers/translation-helper";

export const timeUtil = {
    toHumanReadableTime,
    asHumanTime,
    getHumanDay,
    asTime,
    formatDateString
};

function toHumanReadableTime(millis) {
    let seconds = Math.floor(millis / 1000)
    if (seconds <= 0) {
        return millis + " ms";
    }

    let minutes = Math.floor(seconds / 60)
    seconds %= 60;

    let hours = Math.floor(minutes / 60)
    minutes %= 60;

    let days = Math.floor(hours / 24)
    hours %= 24;

    let years = Math.floor(days / 365)
    years %= 365;

    const time = [];
    if (years > 0) {
        time.push(years)
        time.push(' ');

        if (years === '1') {
            time.push(translationHelper.t("time.year.1"));
        } else if (years <= 4) {
            time.push(translationHelper.t("time.year.2"));
        } else {
            time.push(translationHelper.t("time.year.3"));
        }
    }

    if (days > 0) {
        if (years > 0) {
            time.push(' ');
        }

        time.push(days)
        time.push(' ');

        if (days === 1) {
            time.push(translationHelper.t("time.day.1"));
        } else {
            time.push(translationHelper.t("time.day.2"));
        }
    }

    if (hours > 0) {
        if (days > 0 || years > 0) {
            time.push(' ');
        }

        time.push(hours)
        time.push(' ');

        if (hours === 1) {
            time.push(translationHelper.t("time.hour.1"));
        } else if (hours <= 4) {
            time.push(translationHelper.t("time.hour.2"));
        } else {
            time.push(translationHelper.t("time.hour.3"));
        }
    }

    if (minutes > 0) {
        if (hours > 0 || days > 0) {
            time.push(' ');
        }

        time.push(minutes)
        time.push(' ');

        if (minutes === 1) {
            time.push(translationHelper.t("time.minute.1"));
        } else if (minutes <= 4) {
            time.push(translationHelper.t("time.minute.2"));
        } else {
            time.push(translationHelper.t("time.minute.3"));
        }
    }

    if (seconds > 0) {
        if (minutes > 0 || hours > 0) {
            time.push(' ');
        }

        time.push(seconds)
        time.push(' ');

        if (seconds === 1) {
            time.push(translationHelper.t("time.second.1"));
        } else if (seconds <= 4) {
            time.push(translationHelper.t("time.second.2"));
        } else {
            time.push(translationHelper.t("time.second.3"));
        }
    }

    return time.join("");
}

function asHumanTime(text) {
    if (!text) {
        return '-'
    }

    const number = text.match(/\d+/)[0];
    const type = text.substring(text.indexOf(number) + number.toString().length);

    if (!type) {
        return number;
    }

    const time = [];

    if (type === 'Y') {
        if (number > 0) {
            time.push(number)
            time.push(' ');

            if (number === '1') {
                time.push(translationHelper.t("time.year.1"));
            } else if (number <= 4) {
                time.push(translationHelper.t("time.year.2"));
            } else {
                time.push(translationHelper.t("time.year.3"));
            }
        }
    }

    if (type === 'D') {
        if (number > 0) {
            time.push(number)
            time.push(' ');

            if (time === 1) {
                time.push(translationHelper.t("time.day.1"));
            } else {
                time.push(translationHelper.t("time.day.2"));
            }
        }
    }

    if (type === 'M') {
        if (number > 0) {
            time.push(number)
            time.push(' ');

            if (time === 1) {
                time.push(translationHelper.t("time.month.1"));
            } else {
                time.push(translationHelper.t("time.month.2"));
            }
        }
    }

    return time.join("");
}

function formatDateString(dateString) {
    if (!dateString) {
        return "Brak danych";
    }

    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    return new Intl.DateTimeFormat('pl-PL', options).format(date);
}

function getHumanDay(value, first = false) {
    if (first) {
        if (value === 'DAY') {
            return "dzień"
        } else if (value === 'WEEK') {
            return "tydzień"
        } else if (value === 'MONTH') {
            return "miesiąc"
        } else if (value === 'YEAR') {
            return "rok"
        }

        return ""
    }

    if (value === 'DAY') {
        return "dzień/dni"
    } else if (value === 'WEEK') {
        return "tygodni/tydzień"
    } else if (value === 'MONTH') {
        return "miesiąc/miesiące"
    } else if (value === 'YEAR') {
        return "rok/lata"
    }

    return ""
}

function asTime(value) {
    return value.substring(0, 5);
}
<template>
  <div class="card" :class="cardClass">
    <div class="card-body" :class="bodyClass">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bodyClass: {
      type: String,
      default: ""
    },

    cardClass: {
      type: String,
      default: ""
    }
  }
}
</script>
<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";
import {toastHelper} from "../../../../helpers/toast-helper";
import changeAllegroAccountTypeModal from "../../../../components/allegro/change-allegro-account-type-modal.vue";
import {dateUtil} from "../../../../helpers/date-util";

export default {

  components: {
    Layout,
    PageHeader,
    changeAllegroAccountTypeModal
  },

  computed: {
    paginationHelper() {
      return paginationHelper
    },

    dateUtil() {
      return dateUtil
    }
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",

        items: []
      },

      shops: null
    };
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('admin.allegro-accounts.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "shopId", slot: true, label: this.$t('allegro.table.shop')},
        {key: "name", label: this.$t('allegro.table.account-name')},
        {key: "clientId", label: this.$t('allegro.table.application-id')},
        {key: "type", slot: true, label: "Typ konta"},
        {key: "salesSuspended", slot: true, label: "Blokada sprzedaży Allegro"},
        {key: "createdAt", label: this.$t('allegro.table.created-at'), formatter: value => dateUtil.asDateTime(value)},
        {
          key: "tokenExpiresAt",
          slot: true,
          label: this.$t('allegro.table.token-expires-at')
        },
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    async loadAllegroAccounts() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const {data} = await axios.get(`/allegro/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.table.perPage,
          "filter": this.table.filter ? this.table.filter : ""
        }
      });

      this.table.items = data.allegroAccounts
      this.table.totalRows = data.count;
      this.table.rows = data.count;
      await this.loadShops()

      return this.table.items;
    },

    async loadShops() {
      if (this.table.items.length === 0) {
        return;
      }

      const ids = [];
      for (let allegroAccount of this.table.items) {
        ids.push(allegroAccount.shopId);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const result = await axios.post(`/shop/fetch`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.shops = new Map(result.data.map((obj) => [obj.id, obj]));
    },

    getShop(shopId) {
      if (!this.shops) {
        return null
      }

      return this.shops.get(shopId) || null
    },

    duplicateAccount(account) {
      swalHelper.yesOrNoWithSubTitle(() => {
        const json = {
          allegroAccountToReplaceId: account.id
        }

        axios.post(`/allegro/replace-and-connect`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        }).then(({data}) => {
          window.location = data.redirectUrl;
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, false)
    },

    deleteAccount(account) {
      swalHelper.yesOrNoWithSubTitle(() => {
        axios.delete(`/allegro`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {
            id: account.id
          }
        }).then(() => {
          toastHelper.success(this.$t('allegro.delete.success'))

          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, false)
    },

    isExpiresTime(value) {
      const time = new Date(value);
      const currentTime = new Date();

      return time < currentTime
    },

    changeSalesSuspended(allegroAccount) {
      swalHelper.yesOrNoWithSubTitle(() => {
        const json = {
          allegroAccountId: allegroAccount.id,
          salesSuspended: false
        }

        axios.post(`/allegro/change-sales-suspended`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
        }).then(() => {
          toastHelper.success("Pomyślnie zniesiono blokadę Allegro")
          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        })
      }, false)
    },

    isExpires(item) {
      const time = new Date(item.createdAt);
      const currentTime = new Date();
      const diff = currentTime - time;
      return diff >= 5 * 60 * 1000
    },
  }

};
</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('admin.allegro-accounts.title')"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <ecat-table
                ref="table"
                :fields="getFields()"
                :items="loadAllegroAccounts"
                :pagination-top="true"
                :table="table"
                centered
            >
              <template v-slot:shopId="{ item }">
                <div :set="shop = getShop(item.shopId)">
                  <span v-if="shop">{{ shop.name }}</span>
                </div>
              </template>

              <template v-slot:type="{ item }">
                <span class="badge badge-soft-primary font-size-11"> {{
                    item.type === 'EXTERNAL' ? 'Zewnętrzny' : item.type
                  }}</span>
              </template>

              <template v-slot:salesSuspended="{ item }">
                <span :class="item.salesSuspended ? 'badge-soft-success' : 'badge-soft-danger'"
                      class="font-size-11 badge"> {{ $t(item.salesSuspended ? 'message.yes' : 'message.no') }}</span>
              </template>

              <template v-slot:tokenExpiresAt="{ item }">
                <span v-if="item.tokenExpiresAt">{{ dateUtil.asDateTime(item.tokenExpiresAt) }}</span>
                <span v-if="!item.tokenExpiresAt && isExpires(item)" class="badge badge-soft-danger">Błąd Allegro. Prośba o ponowne podpięcie konta.</span>
              </template>

              <template v-slot:action="{ item }">
                <div class="button-items">
                  <b-button v-if="item.salesSuspended" @click="changeSalesSuspended(item)" variant="success"
                            class="btn-sm">Znieś blokadę sprzedaży Allegro
                  </b-button>
                  <a :href="`/dashboard/user/allegro/after-sale-service/${item.id}`"
                     class="btn btn-outline-warning btn-sm">{{ $t('after-sale-service.title') }}</a>
                  <b-button @click="$refs.changeAllegroAccountTypeModal.openModal(item)" variant="primary"
                            class="btn-sm">Zmień typ konta
                  </b-button>

                  <b-button
                      v-if="(!item.tokenExpiresAt || isExpires(item)) || (item.tokenExpiresAt && isExpiresTime(item.tokenExpiresAt))"
                      class="btn-sm" variant="outline-warning" @click="duplicateAccount(item)">Połącz na nowo
                  </b-button>

                  <b-button @click="deleteAccount(item)" variant="danger" class="btn-sm">Usuń</b-button>
                </div>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <change-allegro-account-type-modal ref="changeAllegroAccountTypeModal" @success="$refs.table.refresh()"/>
  </Layout>
</template>